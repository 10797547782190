import { UCM, SMC, BCCHS } from "../../assets";

const PricingCardData = [
    {
        logosrc: UCM,
        title: "University of California Merced",
        Website:"https://www.ucmerced.edu",
        GPA:"3.85 GPA",
        City: "Merced",
        Diploma: "Bachelors of Science in Engineering - Computer Science and Engineering",
        Content:"UC Merced opened Sept. 5, 2005, as the newest campus in the University of California system and the first American research university of the 21st century. Situated near Yosemite National Park, the campus significantly expands access to the UC system for students throughout the state, with a special mission to increase college-going rates among students in the San Joaquin Valley. It also serves as a major base of advanced research, a model of sustainable design and construction, and a stimulus to economic growth and diversification throughout the region."
    },
    {
        logosrc: SMC,
        title: "Santa Monica College",
        Website:"https://www.smc.edu/",
        GPA:"3.3 GPA",
        City: "Santa Monica",
        Diploma: "Undeclared Major",
        Content:"Santa Monica College (SMC) is a public, community college in Santa Monica, California. Founded as a junior college in 1929, SMC enrolls over 30,000 students in more than 90 fields of study."
    },
    {
        logosrc: BCCHS,
        title: "Birmingham Community Charter High School",
        Website:"https://www.birminghamcharter.com/",
        GPA:"3.2 GPA",
        City: "Los Angeles/Van Nuys",
        Diploma: "High School Diploma",
        Content:"Birmingham Community Charter High School (formerly Birmingham High School) is a charter coeducational high school in the neighborhood/district of Lake Balboa in the San Fernando Valley section of Los Angeles, California, United States. It was founded in 1953 as a 7–12 grade combined high school and became solely a senior high school in 1963."
    }

]
 export default PricingCardData;