import React from "react";
import { Navbar, Footer, Work, IntroImage2, Chatbot } from "../components";

const Project = () => {
  return <div>
    <Chatbot/>
    <Navbar/>
    <IntroImage2 header="PROJECTS" paragraph="ENJOY"/>
    <Work/>
    <Footer/>
    
  </div>;
};

export default Project;
