import { api, api2, api3 } from "../../assets"

const ProjectTwoData = [
    {
        leftside: "LeftSide",
        rightside: "RightSide",
        imgsrc: api,
        title: "React Framework",
        content: "The react offers me to use various packages such as axios to communicate with the API. This will be the source of information for the search engine of the library data such as books and movies.",
        source: "https://github.com/RalphilouT/SearchLibraryWeb"
        
    },
    {
        leftside: "LeftSide1",
        rightside: "RightSide1",
        imgsrc: api2,
        title: "Backend API",
        content: "This backend acts as my source of information that is contacted through the use of npm package such as axios. The API offers security with API key that secures communication between the user and the API. This API was done through spring enabling me to use various dependencies.",
        source: "https://github.com/RalphilouT/-SearchLibraryApi"
    },
    {
        leftside: "LeftSide",
        rightside: "RightSide",
        imgsrc: api3,
        title: "Testing",
        content: "Using the page object model, I am able to create a design pattern in Selenium that creates an object that stpres all web elements that can be reused for each test cases. I also used rest assured to test various communication with the user and the backend. For basic testing of methods, I used junit to compare outputs.",
        source: "https://github.com/RalphilouT/librarytest"
    }
]

export default ProjectTwoData;