import React from "react";
import { Navbar, Footer, IntroImage2, ProjectOne, Chatbot } from "../components";

const Project1 = () => {
  return <div>
    <Chatbot/>
    <Navbar/>
    <IntroImage2 header="React Website" paragraph="In depth summary"/>
    <ProjectOne/>
    <Footer/>
  </div>;
};

export default Project1;
