import React from "react";
import { Navbar, Footer, IntroImage, Chatbot } from "../components";

const Home = () => {
  return(
    <div>
      <Chatbot/>
      <Navbar/>
      <IntroImage/> 
      <Footer/>
      
      
    </div>
  );
};

export default Home;

// automatic type rafce


